import Axios from 'axios';

const baseUrl='https://s3.eu-west-1.amazonaws.com/staging.files.data-services.clearcost.energy/';
const headers = {
    'Access-Control-Allow-Origin':'*'
}

const Post = (data)=>{
    
    return Axios.post(baseUrl, data, headers)
}

export const aws = {Post};