import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FieldList, MpanInput, PeriodSelection } from "./";
import { Gmt } from "./gmt";
import { Http } from "../../../../services";
import { Error } from "../../../common";
import * as moment from 'moment'
import "./create.scss";

export default function Create(props) {
  const [mpan, setMPAN] = useState("");
  const [capacities, setCapacities] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState({});
  const [showError, toggleErrorDisplay] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [gmt, setGmt] = useState(false);
  const formatedDate = (date) => {
    var formated = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD')
    return formated;
  }
  const createReport = () => {
    setErrorMessage({});
    Http.Post(
      `gb-electricity/batches/${localStorage.getItem("guid")}/reports/create/`,
      {
        start_date: formatedDate(startDate),
        end_date: formatedDate(endDate),
        mpan: mpan,
        gmt: gmt,
        available_capacity: capacities.map(capacity => ({
          value: capacity.value,
          start_date: formatedDate(capacity.start_date),
          end_date: formatedDate(capacity.end_date)
        }))
      }
    )
      .then(response => {
        let url = response.data.url.split("/");
        props.submitForm({
          mpan,
          period: { startDate, endDate },
          capacities,
          reportId: url[url.length - 2],
          status: response.data.status
        });
      })
      .catch(error => {
        setErrorMessage(error.response.data);
        toggleErrorDisplay(true);
      });
  };

  return (
    <div className="component-create">
      <MpanInput className="field-cell" setMPAN={setMPAN} />
      <Gmt setGmt={setGmt} className="field-checkbox"></Gmt>
      <PeriodSelection
        className="field-cell"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <span className="line"></span>
      <FieldList capacities={capacities} setCapacities={setCapacities} startDate={startDate} endDate={endDate} />
      <div className="actions">
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={createReport}>
          Submit
        </Button>
      </div>
      {showError && <Error error={errorMessage} />}
    </div>
  );
}
