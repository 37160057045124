import React from "react";
import { default as DatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./period.scss";

export default function Period(props) {
  return (
    <div className={props.className}>
      <span>Report Analysis Period</span>
      <div className="date-selection-cell">
        <span>Start Date:</span>
        <DatePicker
          selected={props.startDate}
          onChange={date => props.setStartDate(date)}
          dateFormat="dd.MM.yyyy"
          showMonthDropdown
          showYearDropdown
        />
        <span>End Date:</span>
        <DatePicker
          selected={props.endDate}
          onChange={date => props.setEndDate(date)}
          dateFormat="dd.MM.yyyy"
          showMonthDropdown
          showYearDropdown
        />
      </div>
    </div>
  );
}
