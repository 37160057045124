import React, { useState, useEffect } from 'react';
import { Http } from '../../../../services';
import { Error, Button } from '../../../common';
import './typeSelection.scss';

export default function TypeSelection(props) {
  const [types, setTypes] = useState([]);
  const [showError, toggleErrorDisplay] = useState(false);
  const [error, setError] = useState({});
  const [selectedType, setSelectedType] = useState({});
  
  useEffect( () => {
      Http.Get('gb-electricity/batches/report-types/')
          .then( response => { 
            setTypes(response.data);
            setSelectedType(response.data[0]);
          })
          .catch( error => { 
            setError(error.response.data);
            toggleErrorDisplay(true);
          });
  }, []);

  const onChange = (event) => {
    setSelectedType(types.find( e => e.label === event.target.value));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    var body= {report_type: selectedType.url};
    Http.Post('gb-electricity/batches/create/',body)
        .then(response =>  {
          let url = response.data.url.split("/");
          localStorage.setItem("guid", url[url.length - 2]);
          props.setAwsData(response.data.upload_info.fields)
          props.onSelected(true);
        })
        .catch( error => { 
          setError(error.response.data);
          toggleErrorDisplay(true);
        });
  }

  return <div className="component-type-selection">
    <form className="form-content" onSubmit={onSubmit}>
      <span className="title">Select batch type:</span>
      <select className="form-control" onChange={onChange}>
        {
          types.map( (elem) => <option key={elem.label}>{elem.label}</option> )
        }
      </select>
      <div className="description-content">
        { selectedType && <span>{selectedType.description}</span> }
      </div>
      <Button className="btn btn-primary" type="submit" text="Submit"/>
    </form>
    { showError && <Error error={error}/> }
  </div>;
}