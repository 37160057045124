import React from "react";
import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import "./App.css";
import { Navbar, Login, ForgotPassword } from "./components";
import { BatchOverview } from "./components/batch";
import "bootstrap/dist/css/bootstrap.min.css";

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    sessionStorage.getItem(localStorageKey) || ""
  );

  React.useEffect(() => {
    sessionStorage.setItem(localStorageKey, value);
  });

  return [value, setValue];
};

const Footer = () => (
  <footer className="footer">
    <p>ClearCost Energy (GB) Ltd, Registered in Scotland No. 384433. Registered Office: Johnstone House, 52-54 Rose Street, Aberdeen AB10 1HA, UK</p>
  </footer>
);

export default function App() {
  const [isAuthorized, setAuthorized] = useStateWithLocalStorage("token");
  const [showPasswordReset, togglePasswordResetDisplay] = React.useState(false);

  return (
    <div className="App">
      <Navbar isAuthorized={isAuthorized} onLogin={setAuthorized} />
      {showPasswordReset ? (
        <ForgotPassword showPasswordReset={togglePasswordResetDisplay} />
      ) : isAuthorized ? (
        <BatchOverview />
      ) : (
        <Login
          onLogin={setAuthorized}
          showPasswordReset={togglePasswordResetDisplay}
        />
      )}
      <Footer></Footer>
    </div>
  );
}
