import React, {useState} from 'react';
import { ReportsRequestCreation, ReportsRequestList } from '../';
import { Modal, Error } from '../../../common';
//import { Http } from '../../../../services';
import './overview.scss';

export default function Overview(props) {
  const [reports, setReports] = useState([]);
  const [showModal, setDisplay] = useState(false);
  const [error, setError] = useState({});
  const [showError, setErrorDisplay] = useState(false);

  const closeDialog = () => setDisplay(false);

  const createRequest = (request) => {
    setDisplay(false);
    reports.push(request);
    setReports([...reports]);
  }

  return <div className="component-overview">
    <ReportsRequestList 
        reports={reports}
        setReports={setReports} 
        showModal={setDisplay} 
        setErrorDisplay={setErrorDisplay}
        setError={setError}/>
    {
      showModal &&
      <Modal show={showModal} header="Create Report Request" handleClose={closeDialog}>
        <ReportsRequestCreation submitForm={createRequest} handleClose={closeDialog}/>
      </Modal>
    }
    {
      showError && <Error error={error}/>
    }
    
  </div>;
}