import React, { useState } from "react";
import "./login.scss";
import { navigate } from "hookrouter";
import { Button, Href } from "../../common";
import { Http } from "../../../services";
import { Error } from "../../common";

export default function Login(props) {
  let loginModel = {};
  const [errorMessage, setErrorMessage] = useState({});
  const [showError, toggleErrorDisplay] = useState(false);
  const showPasswordReset = () => props.showPasswordReset(true);

  function handleChange(event) {
    loginModel[event.target.name] = event.target.value;
  }

  function handleSubmit(event) {
    toggleErrorDisplay(false);
    event.preventDefault();

    Http.Post("auth/login/", loginModel)
      .then(response => {
        props.onLogin(response.data.token);
        navigate("/create-batch", true);
      })
      .catch(error => {
        setErrorMessage(error.response.data);
        toggleErrorDisplay(true);
      });
  }

  return (
    <div className="component-login">
      <div className="logo">
        <img
          className="form-title"
          src={require(`./login.png`)}
          width={150}
          height={150}
          alt={"logo"}
        />
      </div>
      <span className="form-title">Log In</span>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="fieldCell">
            <input
              type="text"
              name="username"
              autoComplete="off"
              placeholder="username"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="fieldCell">
            <input
              type="password"
              name="password"
              placeholder="password"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="actions">
            <Button className="btn btn-primary" type="submit" text="Log In" />
            <Href
              className="btn btn-warning"
              onClick={showPasswordReset}
              text="Forget password?"
            />
          </div>
        </div>
      </form>

      {showError && <Error error={errorMessage} />}
    </div>
  );
}
