import React from "react";
import { MdAdd, MdClear } from "react-icons/md";
import { default as DatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./fieldList.scss";

export default function FieldList(props) {
  const addElement = () =>{
    console.log(props.startDate);
    props.setCapacities([...props.capacities, { id: Date.now(), start_date: props.startDate, end_date: props.endDate }]);
  }


  const removeElement = index => {
    props.capacities.splice(index, 1);
    props.setCapacities([...props.capacities]);
  };

  const onChange = event => {
    const capacities = props.capacities;
    capacities[event.target.dataset.index][event.target.name] =
      event.target.value;
    props.setCapacities([...capacities]);
  };

  const onDateChange = (date, index, name) => {
    const capacities = props.capacities;
    capacities[index][name] = date;
    props.setCapacities([...capacities]);
  };

  return (
    <div className="field-list-component">
      <div className="list-field-cell">
        <div className="list-cell">
          <span>Start Date</span>
          <span>End Date</span>
          <span>kVA</span>
          <MdAdd className="add-icon" onClick={addElement} />
        </div>
        {props.capacities.map((elem, index) => (
          <div className="list-cell" key={elem.id}>
            <DatePicker
              selected={props.capacities[index]["start_date"]}
              onChange={date => onDateChange(date, index, "start_date")}
              dateFormat="dd.MM.yyyy"
              showMonthDropdown
              showYearDropdown
            />
            <DatePicker
              selected={props.capacities[index]["end_date"]}
              onChange={date => onDateChange(date, index, "end_date")}
              dateFormat="dd.MM.yyyy"
              showMonthDropdown
              showYearDropdown
            />
            <input
              data-index={index}
              name="value"
              onChange={onChange}
              autoComplete="off"
              className="list-cell"
            />
            <MdClear
              className="add-icon"
              onClick={() => removeElement(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
