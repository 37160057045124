import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Http } from "../../../services/";
import { Button, Modal, Error } from "../../common";
import { ReportDetail } from "./report-detail";
import "./report-status.scss";

export default function ReportStatus(prors) {
  let [batch, setReports] = useState({});
  let [reportDetail, setReportDetail] = useState({});
  const [error, setErrorMessage] = useState({});
  const [showError, toggleErrorDisplay] = useState(false);
  const [showModal, setDisplay] = useState(false);
  const closeDialog = () => setDisplay(false);

  useEffect(() => {
    let id = 0;
    getBatches().then(() => {
      id = setInterval(() => getBatches(), 30000);
    });
    return () => clearInterval(id);
  }, []);

  const getBatches = () =>
    Http.Get(`gb-electricity/batches/${localStorage.getItem("guid")}/`)
    .then(
      response => {
        setReports(response.data);
      })
      .catch(error => {
        setErrorMessage(error.response.data);
        toggleErrorDisplay(true);
      });

  const showReportDetail = reportId => {
    let reportGuid = reportId.split("/");
    Http.Get(
      `gb-electricity/batches/${localStorage.getItem("guid")}/reports/${
        reportGuid[reportGuid.length - 2]
      }/`
    ).then(response => {
      setReportDetail(response.data);
      setDisplay(true);
    })
    .catch(error => {
      setErrorMessage(error.response.data);
      toggleErrorDisplay(true);
    });
  };

  return (
    <div className="component-overwiev">
      <div className="component-header">
        <span className="title">Batch request overview</span>
      </div>
      <div className="component-header">
        <span className="component-header title">{batch.report_type}</span>
      </div>
      <div className="process">
        {batch.status === "Processing" && (
          <div>
            <div>
              <span>Please wait processing is in progress...</span>
            </div>
            <div>
              <Spinner animation="border" />
            </div>
          </div>
        )}
      </div>
      <div className="process">
        {batch.errors &&
          batch.errors.map((elem, index) => (
            <span className="component-header" key={index}>
              {elem.message}
            </span>
          ))}
        {batch.status === "Ready" && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={batch.result} target="_blank">
            Download result
          </a>
        )}
      </div>
      <div className="result">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {batch.report_requests &&
              batch.report_requests.length > 0 &&
              batch.report_requests.map((elem, index) => (
                <tr key={index}>
                  <td>{elem.status}</td>
                  <td className="detail">
                    <Button
                      className="btn btn-success"
                      type="button"
                      text="Show detail"
                      onClick={() => showReportDetail(elem.url)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      {showModal && (
        <Modal
          show={showModal}
          header="Report Request Detail"
          handleClose={closeDialog}
        >
          <ReportDetail reportDetail={reportDetail} />
        </Modal>
      )}
          {
      showError && <Error error={error}/>
    }
    </div>
  );
}
