import React from 'react';
import { CreateBatch, ReportsRequestOverview, ReportStatus } from './';
import { Login } from '../authentication/login';

export const batchRoutes = {
    '/' : () => <Login/>,
    '/create-batch': () => <CreateBatch/>,
    '/reports-request': () => <ReportsRequestOverview/>,
    '/reports-status': () => <ReportStatus/>
};
