import Axios from 'axios';

const baseUrl = 'https://staging.data-services.clearcost.energy/api/v2/';
const getHeaders = () => {
    return { 
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `JWT ${sessionStorage.getItem('token')}`
        }
    } 
};

const Put = (url) => {
    return Axios.put(`${baseUrl}${url}`, {},getHeaders());
}

const Delete = (url) => {
    return Axios.delete(`${baseUrl}${url}`,getHeaders());
}

const Get = (url) => {
    return Axios.get(`${baseUrl}${url}`, getHeaders());
}

const Post = (url, params) => {
    return Axios.post(`${baseUrl}${url}`, params, getHeaders());
}

const ForgotPassword = (url, params) => {
    return Axios.post(`${baseUrl}${url}`, params);
}

const Logout = (url)=>{
    return Axios.head(`${baseUrl}${url}`,getHeaders());
}

export const http = { Get, Post, Put, Delete, Logout,ForgotPassword };

